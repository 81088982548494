import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'default',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
  },

  {
    path: '/homes',
    component: () => import(/* webpackChunkName: "dashbaord" */ '../views/index.vue'),
    children: [
      {
        path: 'myaccount',
        name: 'myAccount',
        component: () => import(/* webpackChunkName: "myaccount" */ '../views/myAccount/MyAccount.vue'),
      },
      {
        path: ':homeId',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/Homes.vue'),
      },
      {
        path: ':homeId/timeline',
        component: () => import(/* webpackChunkName: "timeline" */ '../views/timeline/Timeline.vue'),
      },
      {
        path: ':homeId/messages',
        component: () => import(/* webpackChunkName: "messages" */ '../views/messages/Messages.vue'),
      },
      {
        path: ':homeId/photos',
        component: () => import(/* webpackChunkName: "photos" */ '../views/photos/Photos.vue'),
      },
      {
        path: ':homeId/documents',
        component: () => import(/* webpackChunkName: "documents" */ '../views/documents/Documents.vue'),
      },
      {
        path: ':homeId/warranty',
        component: () => import(/* webpackChunkName: "warranty" */ '../views/warranty/Warranty.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 Page',
    component: () => import(/* webpackChunkName: "404" */ '../views/404/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
