import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  getAuth, connectAuthEmulator, initializeAuth, browserSessionPersistence,
} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

export const firebaseConfig = {
  apiKey: 'AIzaSyDcBcL7BrZxwBu2J-cxBLBChWxhhgxlSwU',
  authDomain: 'testwrh-cms.firebaseapp.com',
  projectId: 'testwrh-cms',
  storageBucket: 'testwrh-cms.appspot.com',
  messagingSenderId: '354257854527',
  appId: '1:354257854527:web:296ca0de146fb628bb188a',
  measurementId: 'G-LKYJVE1ZQC',
};

let firebaseApp: FirebaseApp;

export const connect = (location: any) => {
  const isLocal = typeof location !== 'undefined' && location.hostname === 'localhost';
  const auth = getAuth();
  if (isLocal) {
    connectAuthEmulator(auth, 'http://localhost:9099');
  }

  const firestore = getFirestore();
  if (isLocal) {
    connectFirestoreEmulator(firestore, 'localhost', 8080);
  }

  const storage = getStorage();
  if (isLocal) {
    connectStorageEmulator(storage, 'localhost', 9199);
  }
};

export const init = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);

    initializeAuth(firebaseApp, {
      persistence: browserSessionPersistence,
    });

    // enabled by default
    // enableIndexedDbPersistence(getFirestore())
    //   .catch((err) => {
    //     if (err.code === 'failed-precondition')
    //       console.warn('Failed to enable offline persistence. Multiple tabs open, persistence can only be enabled in one tab at a time')

    //     else if (err.code === 'unimplemented')
    //       console.warn('Failed to enable offline persistence. The current browser does not support all of the features required to enable persistence')
    //   })
  }
  return connect({});
};
