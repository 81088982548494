import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { init } from './firebase';
/* eslint-disable */
import 'windi.css';
/* eslint-enable */

init();

createApp(App).use(store).use(router).mount('#app');
